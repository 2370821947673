import { Component, HostListener, OnInit } from '@angular/core';
import { ProfissionaisClient } from 'web-api-client';
import { AuthenticationService } from './shared/services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    constructor(private profissionaisClient: ProfissionaisClient,
        private authenticationService: AuthenticationService) {
    }

    // @HostListener('window:beforeunload', ['$event'])
    // unloadHandler(event: Event) {
    //     debugger
    //     console.log('aff');
    //     if (this.authenticationService.obterUsuarioLogado && this.authenticationService.usuarioLogadoIsProfissional)
    //         this.profissionaisClient.alterarSituacaoAtendimento(false).subscribe(r => {
    //             debugger
    //         }, err => {
    //             debugger
    //             console.log(err);
    //         });
    // }

    async ngOnInit() {
        if (Notification.permission !== 'granted')
            await Notification.requestPermission();

        // var notification = new Notification('StartCare Novo Atendimento', {
        //     icon: 'http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png',
        //     body: 'Você recebeu um novo atendimento. Clique para visualizar',
        // });
        // notification.onclick = function () {
        //     window.focus();
        // };
    }
}

