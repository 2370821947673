import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EventService } from 'src/app/shared/services/event.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import {
  AtendimentosClient,
  AtendimentosProfissionaisTotalDiaResult,
} from 'web-api-client';

@Component({
  selector: 'app-total-atendimentos-profissional-dia',
  templateUrl: './total-atendimentos-profissional-dia.component.html',
  styleUrls: ['./total-atendimentos-profissional-dia.component.scss'],
})
export class TotalAtendimentosProfissionalDiaComponent {
  atendimentosProfissionaisTotalDia: AtendimentosProfissionaisTotalDiaResult;
  carregandoAtendimentosProfissionaisTotalDia: boolean;

  exibirDados: boolean;

  private isMenuFoldedChangesSubscription: Subscription;
  private situacaoAtendimentoChangeEventSubscription: Subscription;

  constructor(
    private eventService: EventService,
    private atendimentosClient: AtendimentosClient,
    private themeConstantService: ThemeConstantService
  ) {}

  ngOnInit(): void {
    this.obterQuantidadeConsultas();
    this.situacaoAtendimentoChangeEventSubscription = this.eventService.SituacaoAtendimentoChangeEvent.subscribe(
      () => {
        this.obterQuantidadeConsultas();
      }
    );

    this.isMenuFoldedChangesSubscription = this.themeConstantService.isMenuFoldedChanges.subscribe(
      (r) => {
        this.exibirDados = !r;
      }
    );
  }

  ngOnDestroy() {
    this.isMenuFoldedChangesSubscription &&
      this.isMenuFoldedChangesSubscription.unsubscribe();

    this.situacaoAtendimentoChangeEventSubscription &&
      this.situacaoAtendimentoChangeEventSubscription.unsubscribe();
  }

  private obterQuantidadeConsultas() {
    this.carregandoAtendimentosProfissionaisTotalDia = true;
    this.atendimentosClient
      .obterQuantidadeConsultasDia(null)
      .pipe(
        finalize(() => {
          this.carregandoAtendimentosProfissionaisTotalDia = false;
        })
      )
      .subscribe((r) => {
        this.atendimentosProfissionaisTotalDia = r;
      });
  }
}
