import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: 'dashboard',
        title: 'Painel',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: []
    },
    {
        path: 'atendimentos',
        title: 'Consultas',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-stethoscope',
        submenu: []
    },
    {
        path: 'agendamentos',
        title: 'Agendamentos',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-calendar',
        submenu: []
    },
    {
        path: 'atendimentos/fila',
        title: 'Fila de Atendimento',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-user-friends',
        submenu: []
    },
    {
        path: 'atendimentos/financeiro',
        title: 'Financeiro',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dollar',
        submenu: []
    },
    {
        path: 'atualizacao-cadastral',
        title: 'Atualização Cadastral',
        iconType: 'fontawesome',
        iconTheme: 'far',
        icon: 'fa-address-card',
        submenu: []
    },
    {
        path: 'profissionais',
        title: 'Profissionais',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-user-md',
        submenu: []
    },
    {
        path: 'nova-consulta',
        title: 'Nova Consulta',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-user-md',
        submenu: []
    },
    {
        path: 'atendimentos',
        title: 'Atendimentos',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-stethoscope',
        submenu: []
    },
    {
        path: 'editar-perfil',
        title: 'Editar Perfil',
        iconType: 'fontawesome',
        iconTheme: 'far',
        icon: 'fa-address-card',
        submenu: []
    },
    {
        path: 'disputa',
        title: 'Disputas',
        iconType: 'fontawesome',
        iconTheme: 'far',
        icon: 'fa-address-card',
        submenu: []
    },
    {
        path: 'disponibilidade-retorno',
        title: 'Disponibilidade Retorno',
        iconType: 'fontawesome',
        iconTheme: 'far',
        icon: 'fa-calendar-alt',
        submenu: []
    },
    {
        path: 'administradores',
        title: 'Administradores',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-users-cog',
        submenu: []
    },
    {
        path: 'especialidades',
        title: 'Especialidades',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-briefcase',
        submenu: []
    },
    {
      path: 'assinaturas',
      title: 'Assinaturas',
      iconType: 'fontawesome',
      iconTheme: 'fas',
      icon: 'fa-wallet',
      submenu: []
    },
    {
      path: 'contratos-padrao',
      title: 'Contratos Padrão',
      iconType: 'fontawesome',
      iconTheme: 'fas',
      icon: 'fa-clipboard-list',
      submenu: []
  	},
  	{
        path: 'empresas',
        title: 'Empresas',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-building',
        submenu: []
    },
    {
        path: 'dados-empresa',
        title: 'Dados da Empresa',
        iconType: 'fontawesome',
        iconTheme: 'fas',
        icon: 'fa-building',
        submenu: []
    },
    {
      path: 'admin-assinaturas',
      title: 'Assinaturas',
      iconType: 'fontawesome',
      iconTheme: 'fas',
      icon: 'fa-wallet',
      submenu: []
    },
    {
      path: 'cupons',
      title: 'Cupons',
      iconType: 'fontawesome',
      iconTheme: 'fas',
      icon: 'fa-wallet',
      submenu: []
    },
    {
      path: 'grupos-acesso',
      title: 'Grupos de Acesso',
      iconType: 'fontawesome',
      iconTheme: 'fas',
      icon: 'fa-lock',
      submenu: []
    },
    {
      path: 'usuarios-search',
      title: 'Usuários',
      iconType: 'fontawesome',
      iconTheme: 'fas',
      icon: 'fa-user-injured',
      submenu: []
    }
]
