import { Component, HostListener } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { AuthGuard } from 'src/app/authentication/auth-guard.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subscription } from 'rxjs';
import { AtendimentoVM, EmpresaClient, FilaAtendimentosClient, PacientesClient, ProfissionaisClient, ProfissionalAlterarSituacaoCommand, SituacaoAtendimentoProfissional, SituacaoProfissional, SituacaoProfissionalVm } from 'web-api-client';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SignalRService } from 'src/app/services/signalr.service';
import { finalize } from 'rxjs/operators';
import { EventService } from '../../services/event.service';
import { AlterarSenhaModalComponent } from '../../../atualizacao-cadastral/perfil-card/alterar-senha-modal/alterar-senha-modal.component';
import { AtendimentosTextoModalComponent } from 'src/app/atendimentos/fila/atendimentos-texto-modal/atendimentos-texto-modal';
import { HubConnectionState } from '@microsoft/signalr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  empresaId: number;
  nomeUsuario = '';
  perfil = '';
  usuarioId: 0;
  isLoggedIn$: Observable<boolean>;
  alterandoStatus: boolean;
  avatarUrl: string;
  profissionalId: number;
  exibeLogo: boolean = true;
  plataforma: string;

  situacaoProfissional: SituacaoProfissionalVm;
  existePacienteAguardandoSemProfissional: boolean;
  existeAtendimentoTextoAguardando: boolean;
  hasTriagem: boolean;
  existeMedicoDisponivelParaReceberEncaminhamento: boolean = false;
  atendimentoIdRetornoProximoInicio: number;
  statusSignalR: HubConnectionState;

  private situacaoAtendimentoChangeSub: Subscription;
  private pacienteAguardandoSemProfissionalChangeSub: Subscription;
  private atendimentoTextoAguardandoChangeSub: Subscription;
  private existeMedicoDisponivelParaReceberEncaminhamentoSub: Subscription;
  private signalRChangeSub: Subscription;

  constructor(private themeService: ThemeConstantService,
    private authGuard: AuthGuard,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private profissionaisClient: ProfissionaisClient,
    private pacientesClient: PacientesClient,
    private empresaClient: EmpresaClient,
    private notification: NzNotificationService,
    public signalRService: SignalRService,
    private eventService: EventService,
    private filaAtendimentoClient: FilaAtendimentosClient,
    private modalService: NzModalService
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem("showLogo") == "false") {
      this.exibeLogo = false;
    }
    this.plataforma = localStorage.getItem("plataforma");
    
    const token: string = localStorage.getItem("jwt");

    let tokenDecodificado;

    try {
      tokenDecodificado = this.jwtHelper.decodeToken(token);
    } catch (error) {
      console.log('Erro ao decodificar o token: ' + error);
      this.logOut();
    }

    if (tokenDecodificado) {
      this.nomeUsuario = tokenDecodificado.nome;
      this.perfil = tokenDecodificado.perfil;
      this.usuarioId = tokenDecodificado.usuarioId;
      this.signalRService.connect();
      if (tokenDecodificado.perfil == "Empresa"){
        this.empresaClient.obterPorUsuarioId(this.usuarioId).subscribe(
          (r) => {
            this.obterAvatarEmpresa(r.id);
          },
          (e) => {
            this.notification.error('Erro', 'Empresa não encontrada');
          }
        );        
      }
      if (tokenDecodificado.profissionalId) {
        this.obterAvatar(tokenDecodificado.profissionalId);

        setInterval(() => {
          this.obterSituacaoAtendimento(tokenDecodificado.profissionalId);
        }, 10000);

        this.verificarTriagemUsuario()
        this.obterPacienteAguardandoSemProfissional();
        this.obterAtendimentosTextoAguardando();
        this.detectarMudancaSituacaoAtendimento();
        this.detectarPacienteAguardandoSemProfissional();
        this.detectarAtendimentosTextoAguardando();
        this.detectarRetornoProximoInicio();
        this.detectarMedicoDisponivelParaReceberEncaminhamento();
        this.detectarSignalR();

        this.profissionalId = tokenDecodificado.profissionalId;

        var self = this;
        window.addEventListener("unload", function (event) {
          self.profissionaisClient.alterarSituacaoAtendimento(false).subscribe(res => {
            console.log('profissional offline')
          });
        });

      } if (tokenDecodificado.pacienteId) {
        this.obterAvatarPaciente(tokenDecodificado.pacienteId);
      }
    }

    this.isLoggedIn$ = this.authGuard.isLoggedIn;

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
  }

  ngOnDestroy() {
    this.situacaoAtendimentoChangeSub &&
      this.situacaoAtendimentoChangeSub.unsubscribe();

    this.pacienteAguardandoSemProfissionalChangeSub &&
      this.pacienteAguardandoSemProfissionalChangeSub.unsubscribe();

    this.atendimentoTextoAguardandoChangeSub &&
      this.atendimentoTextoAguardandoChangeSub.unsubscribe();

    this.existeMedicoDisponivelParaReceberEncaminhamentoSub &&
      this.existeMedicoDisponivelParaReceberEncaminhamentoSub.unsubscribe();

    this.signalRChangeSub &&
      this.signalRChangeSub.unsubscribe();
  }


  get isOnline(): boolean {
    return (
      this.situacaoProfissional &&
      this.situacaoProfissional.situacaoAtendimentoProfissional ==
      SituacaoAtendimentoProfissional.Online
    );
  }

  get emAtendimento(): boolean {
    return (
      this.situacaoProfissional &&
      this.situacaoProfissional.situacaoAtendimentoProfissional ==
      SituacaoAtendimentoProfissional.EmAtendimento
    );
  }

  private obterAvatar(profissionalId: number) {
    this.profissionaisClient.obterPorId(profissionalId).subscribe((r) => {
      if (r.fotoBase64)
        this.avatarUrl = `data:image/jpeg;base64,${r.fotoBase64}`;
    });
  }

  private obterAvatarPaciente(pacienteId: number) {
    this.pacientesClient.obterPorId(pacienteId).subscribe((r) => {
      if (r.fotoBase64)
        this.avatarUrl = `data:image/jpeg;base64,${r.fotoBase64}`;
    });
  }

  private obterAvatarEmpresa(empresaId: number) {
    this.empresaClient.obterPorId(empresaId).subscribe((r) => {
      if (r.fotoBase64)
        this.avatarUrl = `data:image/jpeg;base64,${r.fotoBase64}`;
    });
  }

  private obterSituacaoAtendimento(profissionalId: number) {
    this.alterandoStatus = true;
    this.profissionaisClient
      .obterSituacaoAtendimento(profissionalId)
      .pipe(
        finalize(() => {
          this.alterandoStatus = false;
        })
      )
      .subscribe((r) => {
        this.situacaoProfissional = r;

        let situacao = localStorage.getItem("profissionalOnline");

        if (situacao == "true" && this.situacaoProfissional.situacaoAtendimentoProfissional == SituacaoAtendimentoProfissional.Offline) {
          this.profissionaisClient.alterarSituacaoAtendimento(true).subscribe(
            r => {
              this.situacaoProfissional = r;
            }
          )
        }
        this.eventService.SituacaoAtendimentoChangeEvent.emit(r);
      });
  }


  private detectarMudancaSituacaoAtendimento() {
    this.situacaoAtendimentoChangeSub = this.eventService.SituacaoAtendimentoChangeEvent.subscribe(
      (s) => {
        this.situacaoProfissional = s;
      }
    );
  }

  private obterPacienteAguardandoSemProfissional() {
    this.filaAtendimentoClient.existePacienteAguardandoSemProfissional().subscribe(r => {
      this.existePacienteAguardandoSemProfissional = r;
    });
  }

  private obterAtendimentosTextoAguardando() {
    this.filaAtendimentoClient.existeAtendimentoTextoAguardando().subscribe(r => {
      this.existeAtendimentoTextoAguardando = r;
    });
  }

  private detectarMedicoDisponivelParaReceberEncaminhamento() {
    this.existeMedicoDisponivelParaReceberEncaminhamentoSub = this.eventService.existeMedicoDisponivelParaReceberEncaminhamentoEvent.subscribe((r) => {
      this.existeMedicoDisponivelParaReceberEncaminhamento = r;
      console.log(r);
    })
  }

  private detectarPacienteAguardandoSemProfissional() {
    this.pacienteAguardandoSemProfissionalChangeSub = this.eventService.ExistePacienteAguardandoSemProfissionalChangeEvent.subscribe(
      (val) => {
        this.existePacienteAguardandoSemProfissional = val;
      }
    );
  }

  private detectarAtendimentosTextoAguardando() {
    this.atendimentoTextoAguardandoChangeSub = this.eventService.ExisteAtendimentoTextoAguardandoChangeEvent.subscribe(
      (val) => {
        this.existeAtendimentoTextoAguardando = val;
      }
    );
  }

  private detectarRetornoProximoInicio() {
    this.pacienteAguardandoSemProfissionalChangeSub = this.eventService.RetornoProximoInicioChangeEvent.subscribe(
      (val) => {
        this.atendimentoIdRetornoProximoInicio = val;
      }
    );
  }

  private detectarSignalR() {
    this.situacaoAtendimentoChangeSub = this.eventService.SignalRChangeEvent.subscribe(
      (val) => {
        this.statusSignalR = val;
      }
    );
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  logOut() {
    if (this.perfil === 'Profissional') {
      this.profissionaisClient.alterarSituacaoAtendimento(false)
        .subscribe(x => {

        }, err => console.log(err));
    }

    localStorage.removeItem("jwt");
    localStorage.removeItem("refreshToken");
    this.authGuard.setLoggedIn(false);
    if (localStorage.getItem("showLogo") == "false") { 
      this.router.navigate(['authentication/login?showLogo=false']);
    }
    else {
      this.router.navigate(['authentication/login']);
    }
    
    window.location.reload();
  }
  verificarTriagemUsuario(){
    this.profissionaisClient
    .obterTriagemPorProfissional()
    .subscribe((r) => {
      this.hasTriagem = r.valueOf();
    });
   }

  abrirPerfil() {
    if (this.perfil === 'Profissional') {
      this.router.navigate(['/atualizacao-cadastral']);
    }
    if (this.perfil === 'Paciente') {
      this.router.navigate(['/editar-perfil']);
    }
  }

  alterouSituacaoAtendimento() {
    this.alterandoStatus = true;
    this.profissionaisClient.alterarSituacaoAtendimento(!this.isOnline)
      .pipe(finalize(() => { this.alterandoStatus = false; }))
      .subscribe(res => {
        this.eventService.SituacaoAtendimentoChangeEvent.emit(res);

        res.situacaoAtendimentoProfissional == SituacaoAtendimentoProfissional.Online
          ? this.notification.success('Sucesso', 'Seu status foi alterado para Online e você receberá novos atendimentos')
          : this.notification.warning('Sucesso', 'Seu status foi alterado para Offline e você não receberá novos atendimentos');
      }, err => {
        console.log(err);
        this.notification.error('Erro', 'Ocorreu um erro ao alterar seu status de atendimento');
      })
  }

  irParaSalaAtendimento(atendimentoId: number) {
    this.router.navigate(['/atendimentos', atendimentoId, 'sala']);
  }

  notificationList = [
    {
      title: 'You received a new message',
      time: '8 min',
      icon: 'mail',
      color: 'ant-avatar-' + 'blue'
    },
    {
      title: 'New user registered',
      time: '7 hours',
      icon: 'user-add',
      color: 'ant-avatar-' + 'cyan'
    },
    {
      title: 'System Alert',
      time: '8 hours',
      icon: 'warning',
      color: 'ant-avatar-' + 'red'
    },
    {
      title: 'You have a new update',
      time: '2 days',
      icon: 'sync',
      color: 'ant-avatar-' + 'gold'
    }
  ];

  abrirModalAlterarSenha() {
    this.modalService.create({
      nzTitle: 'Alterar Senha',
      nzContent: AlterarSenhaModalComponent,
    });
  }
  abrirModalAtendimentosTexto(){
    this.modalService.create({
      nzTitle: "Atendimentos Texto",
      nzContent: AtendimentosTextoModalComponent, 
    });
  }
}
