<form nz-form [formGroup]="alterarSenhaForm" nzLayout="vertical">
  <div nz-row nzGutter="24">

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="senhaAtual" nzRequired>
          Senha Atual
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="password" formControlName="senhaAtual" id="senhaAtual" placeholder="Senha Atual" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="novaSenha" nzRequired>
          Nova Senha
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="password" formControlName="novaSenha" id="novaSenha" placeholder="Nova Senha"
            (ngModelChange)="updateConfirmValidator()" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="confirmacaoNovaSenha" nzRequired>
          Repita a Nova Senha
        </nz-form-label>
        <nz-form-control [nzErrorTip]="errorTpl">
          <input nz-input type="password" formControlName="confirmacaoNovaSenha" id="confirmacaoNovaSenha"
            placeholder="Repita a Nova Senha" />
          <ng-template #errorTpl let-control>
            <ng-container *ngIf="control.hasError('confirm')">
              Você deve repetir a mesma senha informada no campo "Nova Senha".
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<div *nzModalFooter>
  <button nz-button (click)="destroyModal()">Cancelar</button>
  <button nz-button nzType="primary" (click)="alterarSenha()" [disabled]="alterarSenhaForm.invalid"
    [nzLoading]="saving">
    Salvar
  </button>
</div>