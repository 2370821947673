<nz-card *ngIf="exibirDados && atendimentosProfissionaisTotalDia" nzTitle="Atendimentos"
  [nzLoading]="carregandoAtendimentosProfissionaisTotalDia" class="rounded-0 border-right-0">
  <nz-row nzGutter="24">
    <nz-col nzSpan="17">
      <b>Realizados Hoje</b>
    </nz-col>
    <nz-col nzSpan="7" class="text-center">{{ atendimentosProfissionaisTotalDia.quantidadeConsultas }}</nz-col>
  </nz-row>

  <nz-row nzGutter="24" class="mt-2">
    <nz-col nzSpan="17">
      <b>Recusados Hoje</b>
    </nz-col>
    <nz-col nzSpan="7" class="text-center">{{ atendimentosProfissionaisTotalDia.quantidadeRecusados }}</nz-col>
  </nz-row>

  <nz-row nzGutter="24" class="mt-2">
    <nz-col nzSpan="17">
      <b>Perdidos Hoje</b>
    </nz-col>
    <nz-col nzSpan="7" class="text-center">{{ atendimentosProfissionaisTotalDia.quantidadePerdidos }}</nz-col>
  </nz-row>
</nz-card>
