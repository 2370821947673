import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { finalize } from 'rxjs/operators';
import { UsuariosClient } from 'web-api-client';

@Component({
  selector: 'app-alterar-senha-modal',
  templateUrl: './alterar-senha-modal.component.html',
})
export class AlterarSenhaModalComponent implements OnInit {
  alterarSenhaForm: FormGroup;
  saving: boolean;

  constructor(
    private modal: NzModalRef,
    private notification: NzNotificationService,
    private usuariosClient: UsuariosClient
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.alterarSenhaForm = new FormBuilder().group({
      senhaAtual: [null, Validators.required],
      novaSenha: [null, Validators.required],
      confirmacaoNovaSenha: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    });
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.alterarSenhaForm.controls.confirmacaoNovaSenha.updateValueAndValidity()
    );
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !== this.alterarSenhaForm.controls.novaSenha.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  alterarSenha() {
    this.saving = true;
    this.usuariosClient
      .alterarSenha(this.alterarSenhaForm.value)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe((r) => {
        this.notification.success('Sucesso', 'Senha alterada com sucesso');
        this.destroyModal();
      });
  }

  destroyModal(): void {
    this.modal.destroy();
  }
}
