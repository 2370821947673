<nz-alert nzType="error" *ngIf="ocorreuErroObterAtendimento"
  nzDescription="Ocorreu um erro. Por favor, tente novamente.">
</nz-alert>

<ng-container *ngIf="ehPasso(PassosEnum.AtendimentosPendentes)">
  <nz-space>
    <nz-space-item>
      <button (click)="carregarRegistros()" nz-button nzSize="small" nzType="primary">Atualizar</button>
    </nz-space-item>
  </nz-space>
  <nz-table #borderedTable nzBordered [nzData]="itensFila" [nzPageSize]=5 nzSize="small"
  [nzLoading]="carregandoAtendimentos" class="mt-1">
    <thead>
      <tr>
        <th>Data Entrada</th>
        <th>Usuário</th>
    
      </tr>
    </thead>
    <tbody>
      <tr class="button" *ngFor="let a of borderedTable.data" (click)="abrirModalDetalhe(a)">
        <td>{{ a.dataCriacao | date: 'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ a.pacienteNome }}</td>
      </tr>
    </tbody>
  </nz-table>
  
</ng-container>

<ng-container *ngIf="ehPasso(PassosEnum.AtendimentoDetalhe)">
  <textarea nz-input readonly rows="7">{{atendimento?.textoPerguntaPaciente}}</textarea>
</ng-container>

<ng-container *ngIf="ehPasso(PassosEnum.SelecaoMedico)">
  <nz-space-item>
    <nz-card nzTitle="Redirecionar para:" nzSize="small">
      <nz-radio-group [(ngModel)]="profissionalSelecionado" class="w-100">
        <nz-space-item (click)="selecionarProfissional(0)"
          [ngClass]="{'selecionado': profissionalSelecionado && profissionalSelecionado === '0'}">
          <nz-row nzGutter="12">
            <div nz-col nzSpan="3" nzOffset="1" class="d-flex justify-content-center align-items-center">
              <label nz-radio [nzValue]="0"></label>
            </div>
            <div nz-col nzSpan="20">
              <h6>
                PRÓXIMO DISPONÍVEL
              </h6>
            </div>
          </nz-row>
        </nz-space-item>
        <br/>
        <nz-card *ngFor="let profissional of profissionaisDisponiveis.items"
          (click)="selecionarProfissional(profissional.id)"
          [ngClass]="{'selecionado': profissionalSelecionado && profissionalSelecionado === profissional.id}">
          <nz-row nzGutter="12">
            <div nz-col nzSpan="3" class="d-flex justify-content-center align-items-center">
              <label nz-radio [nzValue]="profissional.id"></label>
            </div>
            <div nz-col nzSpan="21">
              <h6>
                {{ profissional.nome }}
              </h6>
              <label>{{ profissional.especialidades }}</label>
            </div>
          </nz-row>
        </nz-card>
      </nz-radio-group>
    </nz-card>
  </nz-space-item>
</ng-container>
<div *nzModalFooter>
  <button nz-button nzType="default"
  *ngIf="!ehPasso(PassosEnum.AtendimentosPendentes)" 
  [nzSize]="small"
  (click)="voltarPasso()"
  [disabled]="encaminhandoAtendimento">Voltar</button>

  <button nz-button nzType="primary"
  *ngIf="ehPasso(PassosEnum.AtendimentoDetalhe)"
  [nzSize]="small"
  (click)="avancarPasso()">Selecionar Médico</button>

  <button nz-button nzType="primary"
  *ngIf="ehPasso(PassosEnum.SelecaoMedico)"
  [nzSize]="small"
  (click)="encaminharAtendimento()"
  [nzLoading]="encaminhandoAtendimento">
    Encaminhar
  </button>

</div>
