import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack'
import { NzModalService } from 'ng-zorro-antd/modal';
import { API_BASE_URL, FilaAtendimentosClient, ProfissionaisClient, SituacaoAtendimentoProfissional, SituacaoProfissionalVm, PacienteNaFilaModel } from 'web-api-client';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from '../shared/services/authentication.service';
import { EventService } from '../shared/services/event.service';
import { AtendimentoEncontradoModalComponent } from '../shared/modals/atendimento-encontrado/atendimento-encontrado-modal.component';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {

    private hubConnection: HubConnection
    private exibindoModalPacientenEncontrado = false;
    private aceitandoOuRecusandoAtendimento = false;;

    constructor(@Inject(API_BASE_URL) private apiBaseUrl,
        private modal: NzModalService,
        private ngZorroNotification: NzNotificationService,
        private profissionaisClient: ProfissionaisClient,
        private authenticationService: AuthenticationService,
        private filaAtendimentoClient: FilaAtendimentosClient,
        private eventService: EventService) { }

    public connect = () => {
        this.checaFilaProfissional();
        this.startConnection();
        this.addListeners();
    }

    private getConnection(): HubConnection {
        return new HubConnectionBuilder()
            .withUrl(`${this.apiBaseUrl}/signalr`, {
                accessTokenFactory: () => localStorage.getItem('jwt')
            })
            .withAutomaticReconnect([0, 0, 2000, 2000, 5000, 5000, 5000, 5000, 10000])
            .withHubProtocol(new MessagePackHubProtocol())
            .build();
    }

    private startConnection() {

        this.hubConnection = this.getConnection();

        this.hubConnection.start()
            .then(() => {
                console.log('connection started', this.hubConnection.connectionId)
                this.eventService.SignalRChangeEvent.emit(this.hubConnection.state);
            })
            .catch((err) => {
                console.log('error while establishing signalr connection: ' + this.hubConnection.connectionId + err)
                //setInterval(this.startConnection, 5000);
            })
    }

    private addListeners() {
        this.hubConnection.onclose(error => {
            //console.assert(hubConnection.state === signalR.HubConnectionState.Disconnected);
            this.eventService.SignalRChangeEvent.emit(this.hubConnection.state);

            if (this.authenticationService.usuarioLogadoIsProfissional) {
                console.log('onclose', this.hubConnection.connectionId);
                this.modal.confirm({
                    nzTitle: 'Ocorreu uma falha de comunicação com o servidor',
                    nzContent: 'O erro pode ter acontecido por alguma inconsistência no link de comunicação com a internet. Erro: ' + error,
                    nzOnOk: () => window.location.reload(),
                    nzCancelDisabled: true
                });
            }
        });

        this.hubConnection.onreconnected(error => {
            this.eventService.SignalRChangeEvent.emit(this.hubConnection.state);
            //console.assert(hubConnection.state === signalR.HubConnectionState.Disconnected);
            console.log('onreconnected', this.hubConnection.connectionId);
        });

        this.hubConnection.onreconnecting(error => {
            this.eventService.SignalRChangeEvent.emit(this.hubConnection.state);
            this.profissionaisClient
                .alterarSituacaoAtendimento(false)
                .subscribe((r) => {
                    this.eventService.SituacaoAtendimentoChangeEvent.emit(r);
                }, (err) => {
                    const usuarioLogado = this.authenticationService.obterUsuarioLogado;
                    this.eventService.SituacaoAtendimentoChangeEvent.emit(new SituacaoProfissionalVm({
                        profissionalId: usuarioLogado.profissionalId,
                        situacaoAtendimentoProfissional: SituacaoAtendimentoProfissional.Offline
                    }));
                });
            //console.assert(hubConnection.state === signalR.HubConnectionState.Disconnected);
            console.log('onreconnecting' + this.hubConnection.connectionId);
        });


        this.hubConnection.on("pacienteNaFila", (atendimento: any) => {
            console.log("paciente na fila atendimento ", atendimento);
            var notification = new Notification('StartCare Novo Atendimento', {
                icon: 'https://startcare-api.magmati.com.br/logo_menor.png',
                body: 'Você recebeu um novo atendimento. Clique para visualizar',
            });
            notification.onclick = function () {
                window.focus();
            };

            this.modal.create({
                nzContent: AtendimentoEncontradoModalComponent,
                nzComponentParams: {
                    atendimento
                }
            });
        })



        this.hubConnection.on("profissionalRecusouVariasvezes", profissionalId => {
            console.log("inatividade profissional ", profissionalId);
            this.atualizarSituacaoAtendimentoProfissional();
            this.ngZorroNotification.success('Operação realizada com sucesso', 'Sua situção foi marcada como Offline por inatividade. Você pode realizar novos atendimentos quando ficar online novamente');
        })

        this.hubConnection.on("existePacienteAguardandoSemProfissional", value => {
            console.log('addListeners-existePacienteAguardandoSemProfissional', value);
            this.eventService.ExistePacienteAguardandoSemProfissionalChangeEvent.emit(value);
        });

        this.hubConnection.on("existeAtendimentoTextoAguardando", value => {
            console.log('addListeners-existeAtendimentoTextoAguardando', value);
            this.eventService.ExisteAtendimentoTextoAguardandoChangeEvent.emit(value);
        });

        this.hubConnection.on("retornoProximoInicio", value => {
            console.log('addListeners-retornoProximoInicio', value);
            this.eventService.RetornoProximoInicioChangeEvent.emit(value);
        });

        this.hubConnection.on("existeMedicoDisponivelParaReceberEncaminhamento", value => {
            console.log('addListeners-existeMedicoDisponivelParaReceberEncaminhamento', value);
            this.eventService.existeMedicoDisponivelParaReceberEncaminhamentoEvent.emit(value);
        })


        //#region EVENTOS PARA PACIENTE
        this.hubConnection.on("profissionalConcluiuAtendimento", atendimentoId => {
            console.log('addListeners-profissionalConcluiuAtendimento', atendimentoId);
            this.eventService.ProfissionalConcluiuAtendimentoChangeEvent.emit(atendimentoId);
        });

        this.hubConnection.on("profissionalAssumiu", atendimentoId => {
            console.log('addListeners-profissionalAssumiu', atendimentoId);
            this.eventService.ProfissionalAssumiuAtendimentoChangeEvent.emit(atendimentoId);
        });
        //#endregion
    }

    private atualizarSituacaoAtendimentoProfissional() {
        const usuarioLogado = this.authenticationService.obterUsuarioLogado;
        this.profissionaisClient
            .obterSituacaoAtendimento(usuarioLogado.profissionalId)
            .subscribe((r) => {
                this.eventService.SituacaoAtendimentoChangeEvent.emit(r);
            });
    }


    private checaFilaProfissional() {
        this.eventService.ExibindoModalAtendimentoEncontradoEvent.subscribe((exibindoModal: boolean) => {
            this.exibindoModalPacientenEncontrado = exibindoModal;
        });

        this.eventService.AceitandoRecusandoAtendimentoEvent.subscribe((aceitandoOuRecusando: boolean) => {
            this.aceitandoOuRecusandoAtendimento = aceitandoOuRecusando;
        });

        if (this.authenticationService.usuarioLogadoIsProfissional) {
            setInterval(() => {
                if (this.exibindoModalPacientenEncontrado || this.aceitandoOuRecusandoAtendimento)
                    return;

                this.filaAtendimentoClient.profissionalNotificarAtendimento().subscribe(atd => {

                    if (atd == null)
                        return;

                    console.log("paciente na fila atendimento ", atd);
                    var notification = new Notification('StartCare Novo Atendimento', {
                        icon: 'https://startcare-api.magmati.com.br/logo_menor.png',
                        body: 'Você recebeu um novo atendimento. Clique para visualizar',
                    });
                    notification.onclick = function () {
                        window.focus();
                    };

                    this.modal.create({
                        nzContent: AtendimentoEncontradoModalComponent,
                        nzComponentParams: {
                            atendimento: {
                                Cupom: atd.cupom,
                                PacienteNome: atd.pacienteNome,
                                TituloAssinatura: atd.tituloAssinatura,
                                Id: atd.id,
                                QuantidadeAtendimentos: atd.quantidadeAtendimentos
                            }
                        }
                    });
                })

            }, 5000);
        }

    }
}
