import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { finalize } from 'rxjs/operators';
import { PaginatedListOfAtendimentoVM, FilaAtendimentosClient, AtendimentosObterHistoricoQuery, FormatoAtendimento, FilaAtendimentoObterResult, PaginatedListOfProfissionalVm, ProfissionaisClient, ProfissionalObterOnlinePorEspecialidadeQuery, AtendimentosClient, FilaAtendimentoTextoObterPendentesResult } from 'web-api-client';

enum PassosEnum {
  AtendimentosPendentes = 1,
  AtendimentoDetalhe = 2,
  SelecaoMedico = 3,
}

@Component({
  selector: 'app-atendimentos-texto-modal',
  templateUrl: './atendimentos-texto-modal.html',
  styleUrls: ['./atendimentos-texto-modal.css'],
})
export class AtendimentosTextoModalComponent implements OnInit {
  carregandoAtendimentos: boolean;
  encaminhandoAtendimento: boolean;
  ocorreuErroObterAtendimento: boolean;
  itensFila: FilaAtendimentoTextoObterPendentesResult[];
  atendimento: FilaAtendimentoTextoObterPendentesResult;
  PassosEnum = PassosEnum;
  passoAtual =  1;
  profissionaisDisponiveis: PaginatedListOfProfissionalVm;
  profissionalSelecionado: number;

  constructor(
    private router: Router,
    private modal: NzModalRef,
    private formBuilder: FormBuilder,
    private filaAtendimentosClient: FilaAtendimentosClient,
    private notification: NzNotificationService,
    private profissionaisClient: ProfissionaisClient,
    private atendimentosClient: AtendimentosClient,
  ) { }

  ngOnInit(): void {
    this.profissionalSelecionado = 0;
    this.carregarRegistros();
  }

  carregarRegistros() {
    this.ocorreuErroObterAtendimento = false;
    this.carregandoAtendimentos = true;
    this.filaAtendimentosClient
      .obterAtendimentosTextoPendentes()
      .pipe(finalize(() => (this.carregandoAtendimentos = false)))
      .subscribe((r) => {
        this.itensFila = r;
      },
      () => {
        this.ocorreuErroObterAtendimento = true;
      });
  }

  abrirModalDetalhe(atendimento){
    this.atendimento = atendimento;
    this.obterProfissionaisDisponiveis();  
    this.avancarPasso();
  }

  private obterProfissionaisDisponiveis() {
    this.carregandoAtendimentos = true;
    this.profissionaisDisponiveis = null;

    this.profissionaisClient.obterOnline(new ProfissionalObterOnlinePorEspecialidadeQuery(
      { especialidadeId: this.atendimento.especialidadeId }
    ))
      .pipe(
        finalize(() => {
          this.carregandoAtendimentos = false;
        })
      )
      .subscribe(
        (r) => {
          this.profissionaisDisponiveis = r;
        },
        () => {
          this.notification.error('Não há profissionais online', null);
        }
      );
  }

  
  encaminharAtendimento(): void {
    this.encaminhandoAtendimento = true;
    let profissionalId = this.profissionalSelecionado;
    this.atendimentosClient
      .encaminharAtendimentoTextoParaProximoProfissional(this.atendimento.id, profissionalId)
      .pipe(
        finalize(() => {
          this.encaminhandoAtendimento = false;
        })
      )
      .subscribe(() => {
        this.notification.success('Atendimento Encaminhado', null);
        this.destroyModal();
      }, err => {
        this.profissionalSelecionado = 0;
        this.obterProfissionaisDisponiveis();
      })
      
  }

  selecionarProfissional(profissionalId: number) {
    this.profissionalSelecionado = profissionalId;
  }

  avancarPasso() {
    this.passoAtual++;
  }

  voltarPasso() {
    this.passoAtual--;
  }

  ehPasso(passo: number): boolean {
    return this.passoAtual == passo;
  }
  
  destroyModal(): void {
    this.modal.destroy();
  }
}
