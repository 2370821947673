<div class="header">
    <div class="logo logo-dark d-flex align-items-center justify-content-center">
        <a *ngIf="exibeLogo" href="">
            <img src="assets/images/logo/layout_set_logo.png" alt="Logo" height="96">
            <img class="logo-fold" src="assets/images/logo/logo_pequena.png" alt="Logo" height="64">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                Conexão: {{statusSignalR}} |
            </li>
            <li *ngIf="atendimentoIdRetornoProximoInicio"
                (click)="irParaSalaAtendimento(atendimentoIdRetornoProximoInicio)">
                <nz-tag nzColor="orange" class="mb-0">
                    RETORNO PRÓXIMO DO INÍCIO
                </nz-tag>
            </li>
            <ng-container *ngIf="emAtendimento">
                <li (click)="irParaSalaAtendimento(situacaoProfissional.atendimentoId)">
                    <nz-tag nzColor="orange" class="mb-0">
                        EM ATENDIMENTO
                    </nz-tag>
                </li>
                <li (click)="irParaSalaAtendimento(situacaoProfissional.atendimentoId)" class="cursor-pointer">
                    <app-temporizador-atendimento></app-temporizador-atendimento>
                </li>
            </ng-container>
            <li *ngIf="!emAtendimento && perfil === 'Profissional'">
                <nz-switch [ngModel]="isOnline" [nzControl]="true" (click)="alterouSituacaoAtendimento()"
                    nzCheckedChildren="Online" nzUnCheckedChildren="Offline" [nzLoading]="alterandoStatus">
                </nz-switch>
            </li>
            <li *ngIf="existePacienteAguardandoSemProfissional && perfil === 'Profissional'">
                <nz-badge nzDot class="ml-2" nzTooltipTitle="Existe um paciente aguardando atendimento"
                    nzTooltipPlacement="bottom" nz-tooltip>
                    <i nz-icon nzType="team"></i>
                </nz-badge>
            </li>
            <li *ngIf="perfil === 'Profissional' && existeAtendimentoTextoAguardando && hasTriagem" (click)="abrirModalAtendimentosTexto()"
                nzTooltipTitle="Atendimentos Texto Pendentes" style="cursor:pointer"
                    nzTooltipPlacement="bottom" nz-tooltip>
                <nz-badge nzDot class="ml-2">
                    <i nz-icon nzType="file-text"></i>
                </nz-badge>
            </li>
            <li *ngIf="emAtendimento && existeMedicoDisponivelParaReceberEncaminhamento && perfil === 'Profissional'">
                <nz-badge nzDot class="ml-2" nzTooltipTitle="Existe um profissional disponível para encaminhamento"
                    nzTooltipPlacement="bottom" nz-tooltip>
                    <i class="fas fa-stethoscope"></i>
                </nz-badge>
            </li>
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
                    [nzPlacement]="'bottomRight'">
                    <i nz-icon nzType="user" nzTheme="outline"></i>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" nzIcon="user" [nzSrc]="avatarUrl | safe: 'url'"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">{{nomeUsuario}}</p>
                                    <p class="m-b-0 opacity-07" *ngIf="perfil != 'Paciente'">{{perfil}}</p>
                                    <p class="m-b-0 opacity-07" *ngIf="perfil == 'Paciente'">Usuário</p>
                                </div>
                            </div>
                        </li>
                        <!-- <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Visualizar Perfil</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li> -->
                        <li nz-menu-item *ngIf="perfil != 'Administrador' && perfil != 'Empresa'">
                            <a (click)="abrirPerfil()" class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Perfil</span>
                                </div>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a (click)="abrirModalAlterarSenha()"
                                class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="key" theme="outline"></i>
                                    <span class="m-l-10">Alterar Senha</span>
                                </div>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a (click)="logOut()" class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Sair</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </li>
        </ul>
    </div>
</div>