import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

import { registerLocaleData, LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import pt from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { environment } from '../environments/environment'
import { API_BASE_URL, PermissoesAcessoClient } from 'web-api-client';
import { AuthGuard } from './authentication/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './shared/interceptor/http-error.interceptor'
import { JwtInterceptor } from './shared/interceptor/token.interceptor'
import { EventService } from './shared/services/event.service';

import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import listPlugin from '@fullcalendar/list'; // a plugin
import timegridPlugin from '@fullcalendar/timegrid';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { AuthenticationService } from './shared/services/authentication.service';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';



registerLocaleData(pt);

export function tokenGetter() {
    return localStorage.getItem("jwt");
}

FullCalendarModule.registerPlugins([
    // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin,
    listPlugin,
    timegridPlugin
]);

@NgModule({
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        FullLayoutComponent,

    ],
    imports: [
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: [
                    'localhost:5001',
                    'api.startcare.com',
                    'startcare-api.magmati.com.br',
                    'startcare-api.magmati.com.br',
                ],
                blacklistedRoutes: []
            }
        }),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NzBreadCrumbModule,
        TemplateModule,
        SharedModule,
        NgChartjsModule,
        NzCardModule,
        NzInputModule,
        NzTableModule,
        NzSelectModule,
        NzBadgeModule,
        NzAvatarModule,
        NzButtonModule,
        NzIconModule,
        NzNotificationModule,
        NzSpinModule,
        NzModalModule,
        NzSwitchModule,
        SocialLoginModule,
        NgxPermissionsModule.forRoot(),
    ],
    providers: [
        {
            provide: API_BASE_URL,
            useValue: environment.apiBaseUrl
        },
        {
            provide: NZ_I18N,
            useValue: pt_BR,
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
      },
      {
          provide: 'SocialAuthServiceConfig',
          useValue: {
            autoLogin: false,
            providers: [
              {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider(
                  environment.googleAuthId
                )
              },
              {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider(
                  environment.facebookAuthId
                )
              }
            ]
          } as SocialAuthServiceConfig,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (authenticationService: AuthenticationService, permissaoAcessoClient: PermissoesAcessoClient, ps: NgxPermissionsService) => function () {
              if (authenticationService.obterUsuarioLogado && authenticationService.obterUsuarioLogado.perfil == 'Administrador') {
                  permissaoAcessoClient.obterPermissoesUsuarioLogado().subscribe((permissoes) => {
                      console.log('obteve app module');
                      ps.loadPermissions(permissoes);
                  });
              }
          },
          deps: [AuthenticationService, PermissoesAcessoClient, NgxPermissionsService],
          multi: true
        },

        ThemeConstantService,
        EventService,
        AuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
