import { EventEmitter, Injectable } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { ObjectUnsubscribedError } from 'rxjs';
import { ProfissionalVm, SituacaoProfissionalVm } from 'web-api-client';

@Injectable()
export class EventService {
  SituacaoAtendimentoChangeEvent = new CustomEventEmitter<SituacaoProfissionalVm>();
  DadosProfissionalChangeEvent = new CustomEventEmitter<ProfissionalVm>();
  ExistePacienteAguardandoSemProfissionalChangeEvent = new CustomEventEmitter<boolean>();
  ExisteAtendimentoTextoAguardandoChangeEvent = new CustomEventEmitter<boolean>();
  RetornoProximoInicioChangeEvent = new CustomEventEmitter<number>();
  existeMedicoDisponivelParaReceberEncaminhamentoEvent = new CustomEventEmitter<boolean>();
  ExibindoModalAtendimentoEncontradoEvent = new CustomEventEmitter<boolean>();
  AceitandoRecusandoAtendimentoEvent = new CustomEventEmitter<boolean>();

  // EVENTOS PARA PACIENTE
  ProfissionalConcluiuAtendimentoChangeEvent = new CustomEventEmitter<number>();
  ProfissionalAssumiuAtendimentoChangeEvent = new CustomEventEmitter<number>();

  SignalRChangeEvent = new CustomEventEmitter<HubConnectionState>();

  constructor() { }
}
class CustomEventEmitter<T> extends EventEmitter<T> {
  private _value: T;

  constructor() {
    super();
  }

  get value(): T {
    return this.getValue();
  }

  getValue(): T {
    if (this.hasError) {
      throw this.thrownError;
    } else if (this.closed) {
      throw new ObjectUnsubscribedError();
    } else {
      return this._value;
    }
  }

  emit(value?: T) {
    super.emit((this._value = value));
  }
}
