import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { SideNavInterface } from '../../interfaces/side-nav.type';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from '../../services/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissoesAcessoClient, ConfiguracaoClienteClient } from 'web-api-client';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent {

    public menuItems: any[]
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;
    exibirContratacaoPlanoApp: boolean;

    permissions: any;

    constructor(
        private themeService: ThemeConstantService,
        private jwtHelper: JwtHelperService,
        private authenticationService: AuthenticationService,
        private permissionsService: NgxPermissionsService,
        private permissoesAcessoClient: PermissoesAcessoClient,
        private configuracaoClienteClient: ConfiguracaoClienteClient
    ) { }

    ngOnInit(): void {
        try {
            const token = localStorage.getItem("jwt");
            const tokenDecodificado = this.jwtHelper.decodeToken(token);

            const perfil = tokenDecodificado && tokenDecodificado.perfil;            

            if(perfil == 'Administrador'){
              this.permissoesAcessoClient.obterPermissoesUsuarioLogado().subscribe((r) => {
                this.permissionsService.loadPermissions(r);
                this.permissions = this.permissionsService.getPermissions();

                this.menuItems = ROUTES.filter(menuItem => this.permitirExibicaoItemMenu(menuItem, tokenDecodificado));
                this.menuItems.sort((x, y) => {
                  if (x.title > y.title) return 1;
                  if (x.title < y.title) return -1;
                  if (x.title == y.title) return 0;
                });

              })
            }
            else {
              this.configuracaoClienteClient
                  .obter()
                  .subscribe((r) => {
                    this.exibirContratacaoPlanoApp = r.exibirContratacaoPlanoApp;
                    this.menuItems = ROUTES.filter(menuItem => this.permitirExibicaoItemMenu(menuItem, tokenDecodificado));
              this.menuItems.sort((x, y) => {
                if (x.title > y.title) return 1;
                if (x.title < y.title) return -1;
                if (x.title == y.title) return 0;
              });                 
                  },
                  (error) => {
                    this.menuItems = ROUTES.filter(menuItem => this.permitirExibicaoItemMenu(menuItem, tokenDecodificado));
              this.menuItems.sort((x, y) => {
                if (x.title > y.title) return 1;
                if (x.title < y.title) return -1;
                if (x.title == y.title) return 0;
              });  
                  });            
              
            }

        } catch (error) {
            console.log('Erro ao decodificar o token: ' + error);
        }

        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    permitirExibicaoItemMenu(item: SideNavInterface, tokenDecodificado: any): boolean {
        const perfil = tokenDecodificado && tokenDecodificado.perfil;

        if (!perfil) return false;

        if (perfil == 'Administrador') {
            if (item.title == "Consultas")
                return;

            switch (item.path) {
                case 'atendimentos/financeiro':
                  return this.possuiPermissaoFinanceiro;
                case 'atendimentos':
                  return this.possuiPermissaoAtendimentos;
                  case 'agendamentos':
                    return this.possuiPermissaoAgendamentos;
                case 'atendimentos/fila':
                  return this.possuiPermissaoFilaAtendimento;
                case 'profissionais':
                  return this.possuiPermissaoProfissionais;
                case 'disputa':
                  return this.possuiPermissaoDisputas;
                case 'administradores':
                  return this.possuiPermissaoAdministradores;
                case 'especialidades':
                  return this.possuiPermissaoEspecialidades;
                case 'contratos-padrao':
                  return this.possuiPermissaoContratosPadrao;
                case 'empresas':
                  return this.possuiPermissaoEmpresas;
                case 'admin-assinaturas':
                  return this.possuiPermissaoAssinaturas;
                case 'cupons':
                  return this.possuiPermissaoCupons;
                case 'grupos-acesso':
                  return this.possuiPermissaoAcessoTotal;
                case 'usuarios-search':
                    return this.possuiPermissaoPacientes;
                case 'nova-consulta':
                    return this.possuiPermissaoCriacaoConsultasTerceiros;
                default:
                    return false;
            }
        }
        else if (perfil == 'Profissional') {
            if (item.title == "Atendimentos")
                return;
            switch (item.path) {
                case 'dashboard':
                case 'atualizacao-cadastral':
                case 'atendimentos':
                case 'disponibilidade-retorno':
                    return true;
                default:
                    return false;
            }
        }
        else if (perfil == 'Paciente') {
            if (item.title == "Consultas")
                return;
            switch (item.path) {
                case 'nova-consulta':
                case 'atendimentos':
                case 'editar-perfil':
                case 'agendamentos':
                  return true;
                case 'assinaturas':
                  return this.exibirContratacaoPlanoApp;                      

                default:
                    return false;
            }
        }     
        else if (perfil == 'Empresa') {
          if (item.title == "Consultas")
              return;
          switch (item.path) {
              case 'dados-empresa':
                  return true;

              default:
                  return false;
          }
      }    
    }

    get isProfissional(): boolean {
        return this.authenticationService.usuarioLogadoIsProfissional;
    }

    get possuiPermissaoAcessoTotal(): boolean {
      return 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoAdministradores(): boolean {
      return 'Administradores' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoAssinaturas(): boolean {
      return 'Assinaturas' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoAtendimentos(): boolean {
      return 'Atendimentos' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoAgendamentos(): boolean {
      return 'Agendamentos' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoCupons(): boolean {
      return 'Cupons' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoDisputas(): boolean {
      return 'Disputas' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoFinanceiro(): boolean {
      return 'Financeiro' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoFilaAtendimento(): boolean {
      return 'FilaAtendimento' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoProfissionais(): boolean {
      return 'Profissionais' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoEspecialidades(): boolean {
      return 'Especialidades' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoCriacaoConsultasTerceiros(): boolean {
      return 'CriacaoConsultasTerceiros' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoPacientes(): boolean {
      return 'Pacientes' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoEmpresas(): boolean {
      return 'Empresas' in this.permissions || 'AcessoTotal' in this.permissions;
    }
    get possuiPermissaoContratosPadrao(): boolean {
      return 'ContratosPadrao' in this.permissions || 'AcessoTotal' in this.permissions;
    }
}
