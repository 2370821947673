<div nz-row>
  <nz-col nzSpan="21">
    O paciente <span style="font-weight: bold;">{{atendimento.PacienteNome}}</span> está na fila de atendimento <br>
    <br>
    Origem: <span style="font-weight: bold;">{{!!atendimento.TituloAssinatura ? atendimento.TituloAssinatura : "Consulta Avulsa"}}</span> <br>
    <div *ngIf="!!atendimento.Cupom">Cupom: <span style="font-weight: bold;">{{atendimento.Cupom}}</span></div> <br>
    Número de consultas realizadas: <span style="font-weight: bold;">{{atendimento.QuantidadeAtendimentos}}</span> <br>
    <br>
    Deseja realizar o atendimento?
  </nz-col>
  <nz-col *ngIf="contagemRegressivaAtiva" nzSpan="3">
    <nz-progress [nzPercent]="tempoRestante" [nzFormat]="nzProgressFormat" nzType="circle" [nzWidth]="55"></nz-progress>
  </nz-col>
</div>
<div *nzModalFooter>
  <button nz-button (click)="recusar()" [nzLoading]="recusando" [disabled]="aceitando">
    Recusar
  </button>
  <button nz-button nzType="primary" (click)="aceitar()" [nzLoading]="aceitando" [disabled]="recusando">
    Aceitar
  </button>
</div>

<ng-template #justificarRecusaTplModalContent>
  <textarea nz-input [formControl]="justificativaRecusaFormControl" rows="6"></textarea>
</ng-template>
<ng-template #justificarRecusaTplModalFooter let-ref="modalRef">
  <button nz-button nzType="primary" (click)="justificarRecusa(ref)" [disabled]="justificativaRecusaFormControl.invalid">
    OK
  </button>
</ng-template>
